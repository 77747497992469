<template>
  <div class="vx-col w-5/6 ml-auto mr-auto">
    <span>
      <b v-if="id != null">Edit {{ title }}</b>
      <b v-else>Create {{ title }}</b>
    </span>
    <br><br>
    <div class="vx-row mb-6">
      <div class="vx-col w-full">
        <label class="vs-input--label">Data Type</label>
        <multiselect
          v-model="data.roundingType.selected"
          :options="data.roundingType.options"
          placeholder="Type to search"
          :max-height="125"
          v-validate="'required'"
        >
          <span slot="noResult">Oops! No data found</span>
          <template slot="singleLabel" slot-scope="props">
            <span class="option__desc">
              <span class="option__title"
                >{{ props.option.Name }}</span
              >
            </span>
          </template>

          <template slot="option" slot-scope="props">
            <div class="option__desc">
              <span class="option__title"
                >{{ props.option.Name }}</span
              >
            </div>
          </template>
        </multiselect>
        <span class="text-danger text-sm" v-show="errors.has('RoundingType')">{{
          errors.first("RoundingType")
        }}</span>
      </div>
    </div>
    <div class="vx-row mb-6">
      <div class="vx-col w-full">
        <label class="vs-input--label">Rounding Model</label>
        <multiselect
          v-model="data.roundingModel.selected"
          :options="data.roundingModel.options"
          placeholder="Type to search"
          :max-height="125"
          v-validate="'required'"
        >
          <span slot="noResult">Oops! No data found</span>
          <template slot="singleLabel" slot-scope="props">
            <span class="option__desc">
              <span class="option__title"
                >{{ props.option }}</span
              >
            </span>
          </template>

          <template slot="option" slot-scope="props">
            <div class="option__desc">
              <span class="option__title"
                >{{ props.option }}</span
              >
            </div>
          </template>
        </multiselect>
      </div>
    </div>

    <div class="vx-row mb-6">
      <div class="vx-col w-full">
        <vs-input
          class="w-full"
          label="Decimal Point"
          v-validate="'required'"
          v-model="data.decimal_point"
          @keypress="isNumber($event)"
        />
        <span class="text-danger text-sm" v-show="errors.has('DecimalPoint')">{{
          errors.first("DecimalPoint")
        }}</span>
      </div>
    </div>
    <div class="vx-row">
      <div class="vx-col w-full">
        <vs-button class="mr-3 mb-2" @click="handleSubmit">Submit</vs-button>
        <vs-button
          class="ml-4 mt-2"
          type="border"
          color="danger"
          v-on:click="handleClose"
          >Close</vs-button
        >
      </div>
    </div>
  </div>
</template>
<script>
export default {
  components: {},
  props: {
    id: {
      type: Number,
    },
    url: {
      type: String,
    },
    title: {
      type: String,
    },
    dataForm: {
      type: Object,
    },
  },

  data() {
    return this.initialState();
  },
  methods: {
    initialState() {
      return {
        data: {
          id: null,
          name: "",
          decimal_point: 0, 
          roundingTypeId: null,
          roundingModel: {
            options: ["Round", "Round Up", "Round Down"],
            selected: "Round"
          },
          roundingType: {
            options: [],
            selected: null
          },
        },
      };
    },
    handleSubmit() {
      this.$validator.validateAll().then((result) => {
        if (this.data.decimal_point < 0 || this.data.decimal_point > 2) {
          this.errors.add({
            field: "DecimalPoint",
            msg: "The Decimal Point field is required 0-2",
          });
          result = false;
        }

        if (!this.data.roundingType.selected) {
          this.errors.add({
            field: "RoundingType",
            msg: "The Data Type field is required",
          });
          result = false;
        }

        if (result) {
          
          this.$vs.loading();
          if (this.id) {
            this.putData();
          } else {
            this.postData();
          }
        }
      });
    },
    isNumber: function(evt) {
      evt = (evt) ? evt : window.event;
      var charCode = (evt.which) ? evt.which : evt.keyCode;
      if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    paramData() {
      return {
        rounding_type_id: this.data.roundingType.selected.ID,
        rounding_model: this.data.roundingModel.selected,
        decimal_point: parseInt(this.data.decimal_point),
      };
    },
    postData() {
      this.$http
        .post(this.url, this.paramData())
        .then((resp) => {
          this.$vs.loading.close();
          if (resp.code == 200) {
            this.handleClose();
            this.$vs.notify({
              color: "success",
              title: "Success",
              text: "New " + this.title + " Created",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
          } else {
            this.$vs.notify({
              color: "danger",
              title: "Error",
              text: resp.message,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
          }
        })
        .catch((error) => {
          this.$vs.loading.close();
          console.log(error);
        });
    },
    putData() {
      this.$http
        .put(this.url + "/" + this.id, this.paramData())
        .then((resp) => {
          this.$vs.loading.close();
          if (resp.code == 200) {
            this.handleClose();
            this.$vs.notify({
              color: "success",
              title: "Success",
              text: this.title + " Updated",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
          } else {
            this.$vs.notify({
              color: "danger",
              title: "Error",
              text: resp.message,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
          }
        })
        .catch((error) => {
          this.$vs.loading.close();
          console.log(error);
        });
    },
    handleClose() {
      window.scrollTo(0, 0);
      this.$emit("close");
    },
    getData() {
      this.$vs.loading();
      if (this.id) {
        this.data.decimal_point = this.dataForm.rounding.DecimalPoint;
        this.data.roundingModel.selected = this.dataForm.rounding.RoundingModel;
        if (this.dataForm.rounding.RoundingTypeID) {
          this.setRoundingTypeSelected(this.dataForm.rounding.RoundingTypeID);
        }
      }
      // this.$http
      //   .get(this.url + "/" + this.id)
      //   .then((resp) => {
      //     this.$vs.loading.close();
      //     if (resp.code == 200) {
      //       this.data.ID = resp.data.ID;
      //       this.data.decimal_point = resp.data.DecimalPoint;
      //       this.data.roundingModel.selected = this.dataForm.rounding.RoundingModel;
      //       if (resp.data.RoundingTypeID) {
      //         this.setRoundingTypeSelected(resp.data.RoundingTypeID);
      //       }
      //     } else {
      //       this.$vs.notify({
      //         color: "danger",
      //         title: "Error",
      //         text: resp.message,
      //         position: "top-right",
      //         iconPack: "feather",
      //         icon: "icon-x-circle",
      //       });
      //     }
      //   })
      //   .catch((error) => {
      //     this.$vs.loading.close();
      //     console.log(error);
      //   });
    },
    getDataRoundingType() {
      this.$vs.loading();
      this.$http
        .get("/api/v1/master/rounding-type", {
        })
        .then((resp) => {
          if (resp.status == "success") {
            this.data.roundingType.options = resp.data.records;
            this.$vs.loading.close();
          } else {
            this.$vs.loading.close();
          }
        });
    },
    setRoundingTypeSelected(id) {
      this.$vs.loading();
      this.$http.get("/api/v1/master/rounding-type/" + id).then((resp) => {
        if (resp.status == "success") {
          this.data.roundingType.selected = resp.data;
          this.$vs.loading.close();
        } else {
          this.$vs.loading.close();
        }
      });
    },
  },
  mounted() {
    this.getDataRoundingType();
    if (this.id) {
      this.getData();
    }
  },
  computed: {},
  watch: {
    id(v) {
      this.getDataRoundingType();
      if (v) {
        this.getData();
      } else {
        Object.assign(this.$data, this.initialState());
      }
    },
  },
};
</script>
<style >
</style>
